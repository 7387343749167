import { makeStyles } from '@material-ui/core/styles'

const contactStyles = makeStyles((theme) => ({
  bodyContent: {
    marginBottom: theme.spacing(2),
    '& p': {
      lineHeight: '30px',
    },
  },
  sectionHeading: {
    margin: theme.spacing(0, 0, 2, 0),
    fontSize: 30,
    lineHeight: '42px',
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      display: 'inline-block',
      marginLeft: theme.spacing(0.5),
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px',
      textTransform: 'uppercase',
      '&.available': {
        color: theme.palette.primary.light,
      },
      '&.sent': {
        color: theme.palette.secondary.light,
        marginLeft: 0,
        marginRight: theme.spacing(0.5),
        width: 30,
      },
    },
  },
  contactForm: {
    padding: theme.spacing(2, 1.65, 8),
    backgroundColor: theme.palette.background.default,
  },
  contactSent: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    '& p': {
      fontWeight: 600,
    },
  },
  formElement: {
    marginBottom: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
    '&:last-of-type': {
      marginBottom: 0,
      '& > div': {
        width: '90%',
        textAlign: 'right',
      },
    },
    '& > span': {
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      width: 16,
    },
  },
  error: {
    color: theme.palette.primary.light,
  },
  success: {
    color: theme.palette.secondary.light,
  },
}))
export default contactStyles
