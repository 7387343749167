import React, { useContext } from 'react'
import { SettingsContext } from '../../lib/context/SettingsContext'
import classes from '../pages/static-page.module.scss'
import Link from 'next/link'

const StaticSidebar = () => {
  const { settings, navigation } = useContext(SettingsContext)

  return (
    <div className={classes.sticky}>
      <div className={classes.staticMenu}>
        <ul>
          {navigation &&
            navigation
              .filter((menu) => menu.Location === 'Static')[0]
              .menuItems.map((menu, i) => {
                return (
                  <li key={i}>
                    <Link href={menu.url}>{menu.title}</Link>
                  </li>
                )
              })}
        </ul>
      </div>
      <div className={classes.address}>
        <h3>Business Address:</h3>
        <address
          dangerouslySetInnerHTML={{
            __html: settings.address.replaceAll('\n', '<br>'),
          }}
        />
      </div>
    </div>
  )
}
export default StaticSidebar
