import React from 'react'
import Oops from '../images/error.jpg'
import Image from 'next/image'
import Link from 'next/link'
import Meta from '../meta'

const NotFound = () => {
  return (
    <>
      <Meta
        title="Page Not Found"
        description="The page you are looking for might have been removed, had its name changed or is temporarily unavailable."
        keywords=""
        image={null}
        socialMeta={null}
      />
      <div className="error-wrapper py-21">
        <div className="container">
          <div className="max-w-3xl mx-auto">
            <Image
              src={Oops}
              alt="OOPS."
              layout={'responsive'}
              width={2520}
              height={1331}
            />
          </div>
          <h1 className="text-center text-4xl font-display my-12 text-blue">
            404 - Page Not Found
          </h1>
          <p className="text-xl text-center mx-auto max-w-3xl">
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </p>
          <div className="text-center mt-8">
            <Link href="/">
              <a className="inline-block bg-blue text-white text-xl font-semibold text-center py-4 px-8 w-64">
                Go To Homepage
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
export default NotFound
