import Layout from '../components/layout'
import React from 'react'
import StaticPage from '../components/pages/static-page'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { fetchPage } from '../lib/api'
import NotFound from '../components/error/not-found'

const Static = (props) => {
  const { slug, data, errorCode } = props
  return (
    <React.Fragment>
      {slug && (
        <Layout>
          {errorCode === 200 && data.length > 0 && (
            <StaticPage slug={slug} data={data} />
          )}
          {(errorCode === 404 || data.length === 0) && <NotFound />}
        </Layout>
      )}
    </React.Fragment>
  )
}
export const getServerSideProps = async ({ locale, query, req, res }) => {
  const page = await fetchPage({
    page: 'static-pages',
    locale,
    params: { slug: query.slug },
  })
  if (page.errorCode !== 200) {
    res.statusCode = page.errorCode !== 500 ? 404 : page.errorCode
  }
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      slug: query.slug,
      cookie: req.headers.cookie || '',
      ...page,
    },
  }
}
export default Static
