import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'
import Wrapper from '../layout/grid/wrapper'
import ReactMarkdown from 'react-markdown'
import { Container, Grid } from '@material-ui/core'

import classes from './static-page.module.scss'
import Link from 'next/link'
import Meta from '../meta'
import { CMS_NAME } from '../../lib/constants'
import Contours from '../svg/Contours.svg'
import Newsletter from '../newsletter'
import Contact from '../static-layout/contact-us'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { SettingsContext } from '../../lib/context/SettingsContext'
import { STATIC_PAGE } from '../../lib/queries'
import StaticSidebar from '../static-layout/static-sidebar'

const StaticPage = (props) => {
  const { slug, data } = props
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const { settings, navigation } = useContext(SettingsContext)

  const page = data[0]
  // const menu = data.navigationMenus[0]
  // console.log(navigation)

  return (
    <React.Fragment>
      {page.seoMetaData && <Meta {...page.seoMetaData} />}
      <div className={classes.pageHeader}>
        <Contours />
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item>
              <h1 className={classes.pageTitle}>{page.title}</h1>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Wrapper background={'white'}>
        <Container maxWidth="lg">
          <Grid container direction="row" justify="space-between" spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <div
                className={classes.bodyContent}
                dangerouslySetInnerHTML={{ __html: page.content }}
              ></div>
              {page.layout === 'Contact' && <Contact settings={settings} />}
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <StaticSidebar />
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
      <Wrapper background={'White'} withBottomPadding>
        <Container maxWidth={'lg'}>
          <Grid container spacing={3} justify={'center'}>
            <Grid item xs={12} md={9}>
              <Newsletter />
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
    </React.Fragment>
  )
}
StaticPage.propTypes = {
  slug: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}
export default StaticPage
