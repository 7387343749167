import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import contactStyles from './contact-styles'
import { Button, MenuItem, TextField, withStyles } from '@material-ui/core'
import SuccessIcon from '../svg/success.svg'
import ErrorIcon from '../svg/error.svg'

import { validate } from 'validate.js'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

const CONTACT_QUERY = gql`
  mutation contactUs(
    $name: String!
    $subject: String!
    $email: String!
    $recipient: String!
    $message: String
  ) {
    createContact(
      input: {
        data: {
          name: $name
          subject: $subject
          email: $email
          recipient: $recipient
          message: $message
        }
      }
    ) {
      contact {
        id
        name
        created_at
        email
        recipient
        message
      }
    }
  }
`

const Contact = ({ settings }) => {
  const router = useRouter()
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [nameSuccess, setNameSuccess] = useState(false)
  const [enquiry, setEnquiry] = useState('')
  const [enquiryError, setEnquiryError] = useState(false)
  const [enquirySuccess, setEnquirySuccess] = useState(false)
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState(false)
  const [messageSuccess, setMessageSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [sentEnquiry, setSentEnquiry] = useState(false)

  const [contactUs] = useMutation(CONTACT_QUERY)

  const { t, i18n } = useTranslation()
  const classes = contactStyles({})

  useEffect(() => {
    if (router.query.hasOwnProperty('subject')) {
      let i = 0
      for (const subject of settings.contact_subjects) {
        console.log(subject)
        if (subject.subject === router.query.subject) {
          setEnquiry(i.toString())
        }
        i++
      }
    }
  }, [router])
  const WTtextField = withStyles({
    root: {
      width: '100%',
      '& .MuiFilledInput-root': {
        backgroundColor: '#FFFFFF',
        borderRadius: 0,
        border: '1px solid #333333',
      },
      '& .MuiFormLabel-root': {
        fontWeight: 600,
        fontSize: 14,
      },
      '& .MuiFilledInput-underline': {
        '&:after': {
          borderWidth: 4,
        },
      },
      '& .MuiInputBase-input': {
        fontSize: 14,
        fontWeight: 600,
      },
    },
  })(TextField)
  const WTButton = withStyles({
    root: {
      fontSize: 14,
      lineHeight: '18px',
      textTransform: 'initial',
      fontWeight: 600,
      padding: '12px 16px',
    },
  })(Button)

  const handleName = (event) => {
    setName(event.target.value)
    if (event.target.value === '') {
      setNameError(true)
      setNameSuccess(false)
    } else {
      setNameError(false)
      setNameSuccess(true)
    }
  }

  const handleEnquiry = (event) => {
    console.log(event.target.value)
    setEnquiry(event.target.value)
    if (event.target.value === '') {
      setEnquiryError(true)
      setEnquirySuccess(false)
    } else {
      setEnquiryError(false)
      setEnquirySuccess(true)
    }
  }

  const handleMessage = (event) => {
    setMessage(event.target.value)
    if (event.target.value === '') {
      setMessageError(true)
      setMessageSuccess(false)
    } else {
      setMessageError(false)
      setMessageSuccess(true)
    }
  }

  const handleEmail = (event) => {
    setEmail(event.target.value)
    const isValid = validate(
      {
        emailAddress: event.target.value,
      },
      {
        emailAddress: {
          presence: {
            allowEmpty: false,
            message: '^Please enter an email address',
          },
          email: {
            message: '^Please enter a valid email address',
          },
        },
      }
    )
    if (typeof isValid !== 'undefined') {
      setEmailError(true)
      setEmailSuccess(false)
    } else {
      setEmailError(false)
      setEmailSuccess(true)
    }
  }

  const sendContact = (evt) => {
    evt.preventDefault()
    const data = {
      name,
      email,
      subject: settings.contact_subjects[enquiry].subject,
      message,
      recipient: settings.contact_subjects[enquiry].email,
    }
    contactUs({
      variables: data,
    }).then((resp) => {
      setSentEnquiry(true)
    })
  }

  return (
    <>
      {/*<div className={classes.bodyContent}>*/}
      {/*  <h2 className={classes.sectionHeading}>*/}
      {/*    {t('online-chat')}{' '}*/}
      {/*    <span className={'available'}>{t('available')}</span>*/}
      {/*  </h2>*/}
      {/*  <p>{t('chat-intro')}</p>*/}
      {/*</div>*/}
      <div>
        <h2 className={classes.sectionHeading}>{t('contact-form')}</h2>
        {!sentEnquiry && (
          <form
            className={classes.contactForm}
            noValidate
            onSubmit={sendContact}
          >
            <div className={classes.formElement}>
              <WTtextField
                id="your-name"
                label={t('your-name')}
                variant={'filled'}
                error={nameError}
                // onChange={handleName}
                defaultValue={name}
                onBlur={handleName}
              />
              {nameSuccess && (
                <span className={classes.success}>
                  <SuccessIcon />
                </span>
              )}
              {nameError && (
                <span className={classes.error}>
                  <ErrorIcon />
                </span>
              )}
            </div>
            <div className={classes.formElement}>
              <WTtextField
                id="type-of-enquiry"
                label={t('type-of-enquiry')}
                variant={'filled'}
                select
                onChange={handleEnquiry}
                error={enquiryError}
                defaultValue={enquiry}
              >
                {settings.contact_subjects.map((setting, i) => {
                  return (
                    <MenuItem key={setting.subject} value={i}>
                      {setting.subject}
                    </MenuItem>
                  )
                })}
              </WTtextField>
              {enquirySuccess && (
                <span className={classes.success}>
                  <SuccessIcon />
                </span>
              )}
              {enquiryError && (
                <span className={classes.error}>
                  <ErrorIcon />
                </span>
              )}
            </div>
            <div className={classes.formElement}>
              <WTtextField
                id="your-message"
                label={t('how-can-we-help')}
                variant={'filled'}
                multiline
                rows={8}
                rowsMax={8}
                placeholder={t('help-description')}
                error={messageError}
                onBlur={handleMessage}
                defaultValue={message}
              />
              {messageSuccess && (
                <span className={classes.success}>
                  <SuccessIcon />
                </span>
              )}
              {messageError && (
                <span className={classes.error}>
                  <ErrorIcon />
                </span>
              )}
            </div>
            <div className={classes.formElement}>
              <WTtextField
                id="your-email"
                label={t('your-email-address')}
                variant={'filled'}
                onBlur={handleEmail}
                error={emailError}
                defaultValue={email}
              />
              {emailSuccess && (
                <span className={classes.success}>
                  <SuccessIcon />
                </span>
              )}
              {emailError && (
                <span className={classes.error}>
                  <ErrorIcon />
                </span>
              )}
            </div>
            <div className={classes.formElement}>
              <div>
                <WTButton
                  variant="contained"
                  color="secondary"
                  disabled={
                    !(
                      nameSuccess &&
                      enquirySuccess &&
                      messageSuccess &&
                      emailSuccess
                    )
                  }
                  onClick={sendContact}
                >
                  {t('send-enquiry')}
                </WTButton>
              </div>
            </div>
          </form>
        )}
        {sentEnquiry && (
          <div className={classes.contactSent}>
            <h2 className={classes.sectionHeading}>
              <span className={'sent'}>
                <SuccessIcon />
              </span>
              {t('enquiry-sent')}
            </h2>
            <p>{t('enquiry-sent-message')}</p>
          </div>
        )}
      </div>
    </>
  )
}
Contact.propTypes = {
  settings: PropTypes.object,
}
export default Contact
